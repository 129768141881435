import type React from "react"
import { useState, useEffect } from "react"
import {
	type Entity,
	type EntityCollection,
	EntityCollectionView,
	useDataSource,
	useSelectionController,
	useSnackbarController,
} from "@firecms/core"
import { Button, Paper, Typography, CircularProgress, Select, SelectItem, Alert } from "@firecms/ui"

// Collections
import { _BrandsCollection } from "../../collections/_brands"
import { _FirmsCollection } from "../../collections/_firms"
import { _DefendantsCollection } from "../../collections/_defendants"
import { _CourtCasesCollection } from "../../collections/_courtCases"

// Utils
import { updateReferences } from "../../utils/updateReferences"
import { deleteSecondaryEntities } from "../../utils/deleteSecondaryEntities"

// Components
import MergeDialog from "../../components/MergeDialog"

// Types
import type { ADMIN } from "types"

type EntityType = Entity<ADMIN.Defendant | ADMIN.Brand | ADMIN.CourtCase | ADMIN.Firm>
type CollectionType = EntityCollection<ADMIN.Defendant | ADMIN.Brand | ADMIN.CourtCase | ADMIN.Firm>

export const DuplicateView = () => {
	const dataSource = useDataSource()
	const snackbarController = useSnackbarController()
	const tableSelectionController = useSelectionController()

	const [loading, setLoading] = useState(true)
	const [collection, setCollection] = useState<CollectionType>()
	const [mergeDialogOpen, setMergeDialogOpen] = useState(false)

	const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		let newCollection: EntityCollection
		switch (e?.target?.value) {
			case "_defendants":
				newCollection = _DefendantsCollection
				break
			case "_brands":
				newCollection = _BrandsCollection
				break
			case "_cases":
				newCollection = _CourtCasesCollection
				break
			case "_firms":
				newCollection = _FirmsCollection
				break
			default:
				throw new Error("Invalid collection selected")
		}
		setCollection(newCollection)
		tableSelectionController.setSelectedEntities([])
	}

	const handleMergeDuplicates = () => {
		const selectedEntities = tableSelectionController.selectedEntities
		if (selectedEntities.length < 2) {
			alert("Please select at least two items to merge.")
			return
		}
		setMergeDialogOpen(true)
	}

	const handleSelectPrimary = async (primaryEntity: EntityType) => {
		try {
			let fieldToUpdate: string
			switch (collection?.id) {
				case "_defendants":
					fieldToUpdate = "defendants"
					break
				case "_brands":
					fieldToUpdate = "brands"
					break
				case "_cases":
					fieldToUpdate = "cases"
					break
				case "_firms":
					fieldToUpdate = "firms"
					break
				default:
					throw new Error("Invalid collection selected")
			}

			await updateReferences(
				dataSource,
				tableSelectionController.selectedEntities,
				primaryEntity,
				fieldToUpdate,
				collection.path
			)
			await deleteSecondaryEntities(dataSource, tableSelectionController.selectedEntities, primaryEntity)
			tableSelectionController.setSelectedEntities([])

			snackbarController.open({
				type: "success",
				message: "Duplicates merged successfully",
			})
		} catch (error) {
			console.error("Error updating references:", error)
			snackbarController.open({
				type: "error",
				message: "Error merging duplicates",
			})
		} finally {
			setMergeDialogOpen(false)
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setLoading(true)
		const timeoutId = setTimeout(() => {
			setLoading(false)
		}, 500)

		return () => clearTimeout(timeoutId)
	}, [collection])

	return (
		<div className="flex h-full">
			<Paper className="flex flex-col w-full gap-4">
				<Typography variant="h4" className="mx-4 mt-4">
					Find & Merge Duplicates
				</Typography>
				<Alert
					color="base"
					size="large"
					className="font-normal mx-4 w-auto"
					action={
						<Select
							size="small"
							invisible
							placeholder="Select a Collection"
							onChange={handleDropdownChange}
							value={collection?.id}
							renderValue={value => {
								if (value === _DefendantsCollection.id) {
									return _DefendantsCollection.name
								}

								if (value === _BrandsCollection.id) {
									return _BrandsCollection.name
								}
								if (value === _CourtCasesCollection.id) {
									return _CourtCasesCollection.name
								}
								if (value === _FirmsCollection.id) {
									return _FirmsCollection.name
								}
								throw new Error("Invalid value")
							}}
						>
							<SelectItem value={_DefendantsCollection.id}>{_DefendantsCollection.name}</SelectItem>
							<SelectItem value={_BrandsCollection.id}>{_BrandsCollection.name}</SelectItem>
							<SelectItem value={_CourtCasesCollection.id}>{_CourtCasesCollection.name}</SelectItem>
							<SelectItem value={_FirmsCollection.id}>{_FirmsCollection.name}</SelectItem>
						</Select>
					}
				>
					<Typography variant="h5" className="mb-2">
						Instructions
					</Typography>
					<ol>
						<li>1. Select a collection.</li>
						<li>2. Select a set of duplicates in the collection.</li>
						<li>3. Press the merge button.</li>
						<li>4. Choose the duplicate you want to keep.</li>
					</ol>
				</Alert>

				{loading ? (
					<div className="grow flex justify-center items-center h-full">
						<CircularProgress />
					</div>
				) : (
					<>
						{collection && (
							<EntityCollectionView
								{...collection}
								selectionController={tableSelectionController}
								textSearchEnabled={true}
								// propertiesOrder={["name", "duplicate"]}
								defaultSize="xs"
								className="grow"
								Actions={({ selectionController }) => {
									return (
										<Button
											variant="filled"
											color="secondary"
											onClick={handleMergeDuplicates}
											disabled={selectionController.selectedEntities.length < 2}
										>
											Merge Duplicates
										</Button>
									)
								}}
							/>
						)}
					</>
				)}

				<MergeDialog
					open={mergeDialogOpen}
					onClose={() => setMergeDialogOpen(false)}
					entities={tableSelectionController.selectedEntities}
					onSelectPrimary={handleSelectPrimary}
				/>
			</Paper>
		</div>
	)
}
