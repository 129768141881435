import { type PropertyBuilderProps, buildCollection, buildProperty } from "@firecms/core"
import type { ADMIN } from "types"
import LogicBuilder from "../components/LogicBuilder"
import enumColors from "../utils/enumColors"
import DomainProperty from "../properties/DomainProperty"
import DateCreated from "../properties/DateCreated"
import DateUpdated from "../properties/DateUpdated"
import AddressProperty from "../properties/AddressProperty"

export const SettlementsCollection = buildCollection<ADMIN.Settlement>({
	id: "settlements",
	name: "Settlements",
	singularName: "Settlement",
	description: "A list of class action settlements",
	path: "settlements",
	icon: "gavel",
	group: "public",
	selectionEnabled: false,
	textSearchEnabled: false,
	defaultSize: "xs",
	permissions: () => ({
		read: true,
		edit: false,
		create: false,
		delete: true,
	}),
	properties: {
		url: {
			name: "Url",
			description: "The homepage of the class action website",
			dataType: "string",
			url: true,
			validation: { required: true },
		},
		title: {
			name: "Title",
			dataType: "string",
			description: "Use the domain name. https://facebookuserprivacysettlement.com => Facebook User Privacy",
			longDescription: "Unique, human readable name we can use to refer to the case.",
		},
		manualOverrides: {
			name: "Manual Overrides",
			dataType: "map",
			spreadChildren: true,
			properties: {
				isVisible: {
					name: "Is Visible",
					clearable: true,
					description: "Ignore the rest of 'status' and 'dates'. Make the case visible to users.",
					dataType: "string",
					enumValues: [
						{ label: "show", id: "show" },
						{ label: "hide", id: "hide" },
					],
				},
				isFeatured: {
					name: "Is Featured",
					dataType: "boolean",
					description: "Should this appear on the Landing Page?",
				},
				weight: {
					name: "Weight",
					description: "More important cases have a higher weight",
					dataType: "number",
				},
			},
		},
		courtCases: {
			name: "Court Cases",
			dataType: "array",
			description: "Most cases will only have 1 associated case number",
			longDescription:
				"Some cases will have multiple numbers. For example, multiple cases filed against the same company at similar times. Or, FTC cases that also have associated Federal District court cases. Or, cases brought in both state and federal courts.",
			hideFromCollection: true,
			expanded: false,
			of: {
				dataType: "map",
				properties: {
					name: {
						name: "Name",
						description: "",
						dataType: "string",
					},
					caseNumber: {
						name: "Case Number",
						description: "e.g. 2:19-cv-11745-GEM",
						previewAsTag: true,
						dataType: "string",
					},
					jurisdiction: buildProperty({
						name: "Jurisdiction",
						description: "the court that the case was filed in",
						dataType: "string",
						enumValues: [
							{ label: "Federal", id: "federal", color: enumColors.red },
							{ label: "Other", id: "other", color: enumColors.blue },
							{ label: "Alabama", id: "AL", color: enumColors.gray },
							{ label: "Alaska", id: "AK", color: enumColors.gray },
							{ label: "Arizona", id: "AZ", color: enumColors.gray },
							{ label: "Arkansas", id: "AR", color: enumColors.gray },
							{ label: "California", id: "CA", color: enumColors.gray },
							{ label: "Colorado", id: "CO", color: enumColors.gray },
							{ label: "Connecticut", id: "CT", color: enumColors.gray },
							{ label: "Delaware", id: "DE", color: enumColors.gray },
							{ label: "District of Columbia", id: "DC", color: enumColors.gray },
							{ label: "Florida", id: "FL", color: enumColors.gray },
							{ label: "Georgia", id: "GA", color: enumColors.gray },
							{ label: "Hawaii", id: "HI", color: enumColors.gray },
							{ label: "Idaho", id: "ID", color: enumColors.gray },
							{ label: "Illinois", id: "IL", color: enumColors.gray },
							{ label: "Indiana", id: "IN", color: enumColors.gray },
							{ label: "Iowa", id: "IA", color: enumColors.gray },
							{ label: "Kansas", id: "KS", color: enumColors.gray },
							{ label: "Kentucky", id: "KY", color: enumColors.gray },
							{ label: "Louisiana", id: "LA", color: enumColors.gray },
							{ label: "Maine", id: "ME", color: enumColors.gray },
							{ label: "Maryland", id: "MD", color: enumColors.gray },
							{ label: "Massachusetts", id: "MA", color: enumColors.gray },
							{ label: "Michigan", id: "MI", color: enumColors.gray },
							{ label: "Minnesota", id: "MN", color: enumColors.gray },
							{ label: "Mississippi", id: "MS", color: enumColors.gray },
							{ label: "Missouri", id: "MO", color: enumColors.gray },
							{ label: "Montana", id: "MT", color: enumColors.gray },
							{ label: "Nebraska", id: "NE", color: enumColors.gray },
							{ label: "Nevada", id: "NV", color: enumColors.gray },
							{ label: "New Hampshire", id: "NH", color: enumColors.gray },
							{ label: "New Jersey", id: "NJ", color: enumColors.gray },
							{ label: "New Mexico", id: "NM", color: enumColors.gray },
							{ label: "New York", id: "NY", color: enumColors.gray },
							{ label: "North Carolina", id: "NC", color: enumColors.gray },
							{ label: "North Dakota", id: "ND", color: enumColors.gray },
							{ label: "Ohio", id: "OH", color: enumColors.gray },
							{ label: "Oklahoma", id: "OK", color: enumColors.gray },
							{ label: "Oregon", id: "OR", color: enumColors.gray },
							{ label: "Pennsylvania", id: "PA", color: enumColors.gray },
							{ label: "Rhode Island", id: "RI", color: enumColors.gray },
							{ label: "South Carolina", id: "SC", color: enumColors.gray },
							{ label: "South Dakota", id: "SD", color: enumColors.gray },
							{ label: "Tennessee", id: "TN", color: enumColors.gray },
							{ label: "Texas", id: "TX", color: enumColors.gray },
							{ label: "Utah", id: "UT", color: enumColors.gray },
							{ label: "Vermont", id: "VT", color: enumColors.gray },
							{ label: "Virginia", id: "VA", color: enumColors.gray },
							{ label: "Washington", id: "WA", color: enumColors.gray },
							{ label: "West Virginia", id: "WV", color: enumColors.gray },
							{ label: "Wisconsin", id: "WI", color: enumColors.gray },
							{ label: "Wyoming", id: "WY", color: enumColors.gray },
							{ label: "American Samoa", id: "AS", color: enumColors.gray },
							{ label: "Guam", id: "GU", color: enumColors.gray },
							{ label: "Northern Mariana Islands", id: "MP", color: enumColors.gray },
							{ label: "Puerto Rico", id: "PR", color: enumColors.gray },
							{ label: "United States Minor Outlying Islands", id: "UM", color: enumColors.gray },
							{ label: "Virgin Islands", id: "VI", color: enumColors.gray },
						],
					}),
					dateFiled: buildProperty({
						name: "Date Filed",
						description:
							"When was the case first filed? https://pcl.uscourts.gov/pcl/pages/search/findCase.jsf",
						longDescription: "use PACER to find this",
						dataType: "date",
						mode: "date",
					}),
				},
			},
		},
		defendants: {
			name: "Defendants",
			dataType: "array",
			description: "Who is being sued?",
			hideFromCollection: true,
			expanded: false,
			of: {
				dataType: "map",
				properties: {
					name: {
						name: "Name",
						description: "What is the official company name?",
						longDescription: "e.g. 'Meta Platforms, Inc.'",
						dataType: "string",
					},
					logo: buildProperty({
						dataType: "string",
						name: "Logo",
						storage: {
							storagePath: "images",
							acceptedFiles: ["image/*"],
							metadata: {
								cacheControl: "max-age=1000000",
							},
							fileName: context => {
								return context.file.name
							},
						},
					}),
				},
			},
		},
		brands: {
			name: "Brands",
			dataType: "array",
			description: "What's the brand name of the product, service, or company involved?",
			expanded: false,
			hideFromCollection: true,
			of: {
				dataType: "map",
				properties: {
					name: {
						name: "Name",
						description: "A trademark or other name that the consumer will recognize",
						longDescription: "e.g. 'Facebook', 'Oculus'",
						dataType: "string",
					},
					image: {
						name: "Image",
						dataType: "string",
						storage: {
							storagePath: "images",
							acceptedFiles: ["image/*"],
							maxSize: 1024 * 1024,
							metadata: {
								cacheControl: "max-age=1000000",
							},
							fileName: context => {
								return context.file.name
							},
						},
					},
				},
			},
		},
		firms: {
			name: "Law Firms",
			dataType: "array",
			description: "The Law Firms representing parties in the case",
			hideFromCollection: true,
			expanded: false,
			of: {
				dataType: "map",
				properties: {
					name: {
						name: "Name",
						description: "",
						dataType: "string",
					},
					address: AddressProperty,
				},
			},
		},
		category: buildProperty({
			name: "Category",
			dataType: "string",
			description: "Which category best fits the case?",
			longDescription:
				"https://docs.google.com/document/d/1bnOyczEOvSzVn9cSqWYM_QiPg5EwG0uLx7kLRq1_RlQ/edit?usp=sharing",
			enumValues: {
				unfairPracticesAndDeception: "Unfair Practices & Deception",
				financialMisconduct: "Financial Misconduct",
				workplaceRights: "Workplace Rights",
				healthAndSafety: "Health & Safety",
				privacyViolations: "Privacy Violations",
				accessAndEquality: "Access & Equality",
				productAndServiceFailures: "Product & Service Failures",
			},
		}),
		domains: {
			name: "Domains",
			dataType: "array",
			description: "How can we identify who is eligible to apply?",
			longDescription:
				"https://docs.google.com/document/d/1bnOyczEOvSzVn9cSqWYM_QiPg5EwG0uLx7kLRq1_RlQ/edit?usp=sharing",
			expanded: false,
			of: DomainProperty,
		},
		reason: {
			name: "Reason",
			dataType: "string",
			description: "What's the specific reason the case is happening?",
			longDescription: "e.g. 'Contaminated Food', 'Undisclosed Fees', 'Unpaid Overtime', 'Data Breach', etc.",
		},
		value: {
			name: "Value",
			dataType: "map",
			hideFromCollection: true,
			expanded: false,
			properties: {
				total: {
					name: "Total",
					description: "What's the total the defendant settled for?",
					longDescription: "e.g. $5,000,000",
					dataType: "number",
				},
				totalMin: {
					name: "Total Min",
					description: "What's the minimum one person will get?",
					longDescription: "e.g. at least $200, more if you meet other criteria",
					dataType: "number",
				},
				totalMax: {
					name: "Total Max",
					description: "What's the maximum one person will get?",
					longDescription: "e.g. up to $25",
					dataType: "number",
				},
				individual: {
					name: "Individual",
					description: "Roughly, what will one person get?",
					dataType: "number",
				},
				individualMin: {
					name: "Individual Min",
					description: "What's the minimum one person will get?",
					longDescription: "e.g. at least $200, more if you meet other criteria",
					dataType: "number",
				},
				individualMax: {
					name: "Individual Max",
					description: "What's the maximum one person will get?",
					longDescription: "e.g. up to $25",
					dataType: "number",
				},
			},
		},
		dates: {
			name: "Dates",
			previewProperties: ["applyDeadline"],
			description: "The important dates of this case",
			dataType: "map",
			hideFromCollection: true,
			expanded: false,
			properties: {
				applyDeadline: {
					name: "Apply",
					description: "What's the last day to apply for the case?",
					dataType: "date",
					mode: "date",
				},
				objectDeadline: {
					name: "Object",
					description: "What's the last day to object to the case?",
					dataType: "date",
					mode: "date",
				},
				optOutDeadline: {
					name: "Opt Out",
					description: "What's the last day to opt-out of the case?",
					dataType: "date",
					mode: "date",
				},
				approvalHearingDate: {
					name: "Approval Hearing",
					description: "When is the the final approval hearing?",
					dataType: "date",
					mode: "date",
				},
				expectedPaymentDate: {
					name: "ExpectedPayment",
					description: "When will people get their money?",
					dataType: "date",
					mode: "date",
				},
				trialDate: {
					name: "Trial Date",
					description: "When is the case scheduled to go to trial?",
					dataType: "date",
					mode: "date",
				},
			},
		},
		proofRequired: buildProperty({
			name: "Proof Required",
			description: "Does the user need to provide proof they're eligible for this settlement?",
			longDescription: "e.g. receipt, claim letter",
			dataType: "string",
			hideFromCollection: true,
			enumValues: [
				{ id: "yes", label: "Yes", color: "greenDarker" },
				{ id: "no", label: "No", color: "redDarker" },
				{ id: "unknown", label: "Unknown", color: "grayLight" },
			],
		}),
		status: {
			name: "Status",
			dataType: "map",
			expanded: false,
			properties: {
				isInvestigationConcluded: {
					name: "Investigation Concluded",
					dataType: "boolean",
				},
				isFiled: {
					name: "Is Filed",
					dataType: "boolean",
				},
				isAppealed: {
					name: "Is Appealed",
					dataType: "boolean",
				},
				isDismissed: {
					name: "Is Dismissed",
					dataType: "boolean",
				},
				hasClassCertification: {
					name: "Has Class Certification",
					dataType: "boolean",
				},
				hasPreliminarySettlementAgreement: {
					name: "Has Preliminary Settlement Agreement",
					dataType: "boolean",
				},
				isNoLongerActive: {
					name: "Is No Longer Active",
					dataType: "boolean",
				},
				hasFinalCourtApproval: {
					name: "Has Final Court Approval",
					dataType: "boolean",
				},
				areAllEligibleClaimantsIdentified: {
					name: "All Eligible Claimants Identified",
					dataType: "boolean",
				},
				areClaimsRequired: {
					name: "Claims Required",
					dataType: "boolean",
				},
				claimsOpenStatus: {
					name: "Claims Open Status",
					dataType: "string",
					enumValues: [
						{ id: "notYetOpen", label: "notYetOpen" },
						{ id: "ongoing", label: "ongoing" },
						{ id: "complete", label: "complete" },
					],
				},
				claimsReviewStatus: {
					name: "Claims Review Status",
					dataType: "string",
					enumValues: [
						{ id: "notYetReviewed", label: "notYetReviewed" },
						{ id: "ongoing", label: "ongoing" },
						{ id: "complete", label: "complete" },
					],
				},
				claimsSubmitMethods: {
					name: "Claims Submit Methods",
					dataType: "array",
					of: {
						dataType: "string",
						enumValues: [
							{ id: "online", label: "online" },
							{ id: "phone", label: "phone" },
							{ id: "mail", label: "mail" },
						],
					},
				},
				paymentStatus: {
					name: "Payment Status",
					dataType: "string",
					enumValues: [
						{ id: "unknown", label: "unknown" },
						{ id: "notYetStarted", label: "notYetStarted" },
						{ id: "ongoing", label: "ongoing" },
						{ id: "delayed", label: "delayed" },
						{ id: "complete", label: "complete" },
					],
				},
				isCanadian: {
					name: "is Canadian",
					description:
						"Only set this value to true if Americans are not allowed to apply. For example: A settlement filed in a Canadian Court, or open to Canadian citizens only.",
					dataType: "boolean",
				},
			},
		},
		form: {
			name: "Form",
			description: "List of questions used to test eligibility",
			dataType: "array",
			hideFromCollection: true,
			of: (questionProps: PropertyBuilderProps) => ({
				name: "Question",
				dataType: "map",
				expanded: false,
				properties: {
					justification: {
						name: "justification",
						dataType: "string",
					},
					question: {
						name: "Linked Question",
						description: "A question that belongs to this form",
						dataType: "reference",
						path: "generalQuestions",
						previewProperties: ["text", "type"],
					},
					logicJumps: {
						name: "Logic Jumps",
						description: "List of list of jumps to execute based on different outcomes.",
						dataType: "array",
						expanded: false,
						oneOf: {
							typeField: "type",
							properties: {
								question: {
									name: "Question",
									dataType: "map",
									properties: {
										condition: buildProperty({
											name: "Conditions",
											dataType: "string",
											Field: LogicBuilder,
											customProps: {
												question: questionProps?.propertyValue?.question,
											},
										}),
										question: {
											name: "Question",
											description:
												"The 'ref' value for the next question the Logic Jump leads to.",
											dataType: "reference",
											path: "generalQuestions",
											previewProperties: ["text"],
										},
									},
								},
								result: {
									name: "Result",
									dataType: "map",
									properties: {
										condition: buildProperty({
											name: "Conditions",
											dataType: "string",
											Field: LogicBuilder,
											customProps: {
												question: questionProps?.propertyValue?.question,
											},
										}),
										result: {
											name: "Result",
											description: "The result of the form.",
											dataType: "string",
											enumValues: [
												{
													id: "eligible",
													label: "Eligible",
													color: enumColors.green,
												},
												{
													id: "possiblyEligible",
													label: "Possibly Eligible",
													color: enumColors.yellow,
												},
												{
													id: "inEligible",
													label: "Ineligible",
													color: enumColors.red,
												},
											],
										},
									},
								},
							},
						},
					},
				},
			}),
		},
		shortSummary: {
			dataType: "string",
			markdown: true,
		},
		llmComments: {
			name: "LLM Comments",
			dataType: "string",
			markdown: true,
			hideFromCollection: true,
			readOnly: true,
		},
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
})
